<template>
    <div>
        <div class="ml-10 mr-10">
            <TitleBar :text="this.$t('menu.api_doc')"></TitleBar>
        </div>
        <RedocWrapper :options="{
                            hideDownloadButton: true,
                            pathInMiddlePanel: true,
                            sortPropsAlphabetically: true
                      }"
                      :spec-or-spec-url="Accounting.getUrl() + '/swagger.json'"
        ></RedocWrapper>
    </div>

</template>

<style scoped>
</style>

<script>
import TitleBar from "@/components/TitleBar.vue";
import RedocWrapper from "@hnluu8/vue-redoc-wrapper";
import Accounting from "@/helpers/Accounting";

export default {
    name: "Documentation",
    computed: {
        Accounting() {
            return Accounting;
        }
    },
    components: {TitleBar, RedocWrapper},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.api_doc"),
                to: "/docs",
                exact: true
            }
        ]);
    }
};
</script>